import React from 'react'
import { graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'
import SEO from '../components/seo/SEO'
import Texte from '../components/Texte'
import Cta from '../components/Cta'

// export const pageQuery = graphql`
// 	query Velo($uid: String!, $locale: String!, $categories: [String]!) {
export const pageQuery = graphql`
  query DefaultQuery($slug: String!, $locale: String!) {
    sanityPage(slug: {current: {eq: $slug}}, locale: {eq: $locale}) {
      ...page
    }
  }
`

const Default = ({data : { sanityPage }}) => {
  // const {data: { sanityPage }, errors} = props
  const {
    title,
    texte,
    seo
  } = sanityPage
//console.log(texte)

  const _replaceUrls = (input, search, replace) => {
    input.forEach(el => {
      const source = el.children[0].text
      if(source.indexOf(search) > -1) {
        //const clean = source.replace(search, replace)
        el.children[0].text = source.replace(search, replace)
      }
    })
    return input
  }
// const banner = seo.image && seo.image.asset ? seo.image.asset.url : 'https://polarstation.fondationtaraocean.org/static/tara-polar-station-home-0b74b3107c10922d6f6a7b922fa7d424.jpg'
  return (
    <div className="home">
      <SEO
        pageTitle={title}
        pageDescription={seo.description}
        template="template-default"
        pageBanner={seo && seo.image && seo.image.asset.url
          ? seo.image.asset.url 
          : "https://cdn.sanity.io/images/dyrwy3jp/production/61908d352b0422cb8a1923ed2ff3bd0c2a6a9976-1440x900.jpg"}
        page={true}
        // pathname={location.pathname}
      />

      <div className="container">
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <h1>{title}</h1>
            <Texte 
            texte={_replaceUrls(texte, 
              'https://polarstation.fondationtaraocean.org', 
              'https://tips.fondationtaraocean.org')} 
            className="fXS"/>
          </div>
    
        </div>
      </div>
      
    </div>
  )
}

export default Default
